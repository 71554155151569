/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                $(function() {
                    $('a[href*=#]:not([href=#])').click(function() {
                        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                            var target = $(this.hash);
                            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                            if (target.length) {
                                $('html,body').animate({
                                    scrollTop: target.offset().top
                                }, 1000);
                                return false;
                            }
                        }
                    });
                });

                function setHeight() {
                    windowHeight = $(window).innerHeight();
                    $('.banner').css('min-height', windowHeight);
                }
                setHeight();

                $(window).resize(function() {
                    setHeight();
                });

                $(function () {
                    var fx = function fx() {
                        var dfd = $(".spin").map(function (i, el) {
                            var data = parseInt(this.dataset.n, 10);
                            var props = {
                                "from": {
                                    "count": 0
                                },
                                "to": {
                                    "count": data
                                }
                            };
                            return $(props.from).animate(props.to, {
                                duration: 1000,
                                step: function (now, fx) {
                                    $(el).text(Math.ceil(now));
                                },
                                complete: function() {
                                    if (el.dataset.sym !== undefined) {
                                        el.textContent = el.textContent.concat(el.dataset.sym)
                                    }
                                }
                            }).promise();
                        }).toArray();
                        // return jQuery promise when all animations complete
                        return $.when.apply($, dfd);
                    };

                    var reset = function reset() {
                        console.log($(this).scrollTop());
                        // do stuff when window `.scrollTop()` > 75
                        if ($(this).scrollTop() > 50) {
                            // turn off scroll event so `fx` not called
                            // during ongoing animation
                            $(this).off("scroll");
                            // when all animations complete
                            fx()
                        }
                    };
                    // if `fx` should only be called once ,
                    // change `.on()` to `.one()` ,
                    // remove `.then()` callback following `fx()`
                    // within `reset`
                    $(window).on("scroll", reset);
                });



            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page


                // Set slide show height
                var slide_show = $('#slide-show');
                function set_slide_show_height() {
                    var height = $(window).height() - 95;
                    slide_show.css('height', height);
                }
                set_slide_show_height();
                $(window).resize(set_slide_show_height);


                // Set Fader slide show
                var fader_imgs = $('#slide-show .slide-image');
                var fade_speed = 1000;
                var fade_delay = 5000;

                // Only fade between images if there is more than one
                if (fader_imgs.length > 1) {

                    var img_index = 0;
                    var z_index_show = 1;
                    var z_index_hide = 0;

                    fader_imgs.each(function(index, element) {
                        element.style.opacity = 0;
                        element.style.display = 'none';
                        element.style.zIndex = z_index_hide;
                    });

                    function _fade_out(img) {
                        $(img).fadeTo(fade_speed, 0, function() {
                            img.style.display = 'none';
                            img.style.zIndex = z_index_hide;
                        });
                    }

                    function _fade_in(img) {
                        img.style.zIndex = z_index_show;
                        img.style.display = 'block';
                        $(img).fadeTo(fade_speed, 1);
                    }

                    _fade_in(fader_imgs[img_index]);

                    function _next_img() {
                        // Remove image
                        _fade_out(fader_imgs[img_index]);

                        img_index++;

                        if (img_index == fader_imgs.length)
                            img_index = 0;

                        // Add image
                        _fade_in(fader_imgs[img_index]);
                    }

                    setInterval(_next_img, fade_delay);
                }


                //var slide_show_images = $('#slide-show .slide-image');
                //
                //if (slide_show_images.length > 1) {
                //
                //    var current_slide_image = 0;
                //
                //    console.log(slide_show_images);
                //
                //    function change_image() {
                //        current_slide_image++;
                //
                //        console.log(current_slide_image);
                //        $(this).css('display', 'none');
                //        $(slide_show_images[current_slide_image]).delay(3000).fadeOut('slow', change_image);
                //    }
                //
                //    $(slide_show_images[current_slide_image]).delay(3000).fadeOut('slow', change_image);
                //
                //    //slide_show_images.each(function(index, element) {
                //    //    console.log($(element).children());
                //    //});
                //
                //}

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        },
        // FAQs
        'faqs': {
            init: function() {
                $('.expand').click(function() {
                    var target = $(this).data('target');
                    $(target).toggleClass('collapse');
                    $(this).toggleClass('expand');
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
